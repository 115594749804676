<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Terms of Service</h1>
        <p>Last updated: September 14, 2023</p>
        <p>By checking the box during registration, you agree to these terms.</p>

        <v-divider></v-divider>

        <h2>Acceptance of Terms</h2>
        <p>By accessing or using the VidToBlog services, you agree to comply with and be bound by these Terms of Service.</p>

        <v-divider></v-divider>

        <h2>Usage Tiers</h2>
        <ul>
          <li>Free: Up to 5 conversions</li>
          <li>Pro: Up to 100 conversions</li>
          <li>Premium: Up to 300 conversions</li>
        </ul>

        <v-divider></v-divider>

        <h2>Payment and Renewal</h2>
        <p>Pro and Premium tiers require payment, which is processed through Stripe. Failure to make payment will result in a downgrade to the Free tier.</p>

        <v-divider></v-divider>

        <h2>Refund Policy</h2>
        <p>All sales are final. No refunds will be issued, except as required by law.</p>

        <v-divider></v-divider>

        <h2>Limitations</h2>
        <p>We are not responsible for the content you convert using this service. You are responsible for ensuring that your content complies with applicable laws.</p>

        <v-divider></v-divider>

        <h2>Content Ownership</h2>
        <p>You retain all ownership rights to the content submitted to VidToBlog. By using the service, you grant us permission to use your content for the purpose of providing the service.</p>

        <v-divider></v-divider>

        <h2>Data Collection and Analytics</h2>
        <p>We use third-party analytics services like Hotjar to improve user experience and for internal analytics. By using our services, you consent to this data collection.</p>

        <v-divider></v-divider>

        <h2>Termination</h2>
        <p>We reserve the right to terminate or suspend your account at our sole discretion, without notice, for conduct that violates these Terms or is harmful to other users, us, or third parties.</p>

        <v-divider></v-divider>

        <h2>Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. Your continued use of the service signifies your acceptance of changes to these Terms.</p>

        <v-divider></v-divider>

        <h2>Disclaimer</h2>
        <p>The services are provided "as is" without any warranties of any kind, either expressed or implied.</p>

        <v-divider></v-divider>

        <h2>Governing Law</h2>
        <p>These Terms are governed by the laws of Ontario, Canada.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UpdatedTermsOfService"
};
</script>
